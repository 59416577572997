@import "../_variables.scss";

#projectTitle {
    font: $font;
    color: $font_color;
    text-align: center;
    font-size: 300%;
    margin-bottom: 7%;
    margin-top: 5%;


}