
#textPicBar {
  width: 100%;
  z-index: -1;
  padding: 0%;
  position: relative;
  display: flex;
  flex-direction: column;
}

#text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#para {
  margin-top: 7%;
  width: 30%;
}
#skidsebbe {
  margin-top: 7%;
  width: 30%;
  height: 50%;
  object-fit: cover;
  margin-left: 10%;
}

@media screen and (max-width: 400px) {

  #text {
    display: flex;
    flex-direction: column-reverse;
    align-self: center;
    width: 80%;
  }

  #skidsebbe {
    margin-top: 3%;
    width: 100%;
    height: 50%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 15%;
  }
  #para {
    margin-top: 3%;
    width: 100%;
    text-align: center;
    margin-bottom: 15%;
  }
}
