html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(12, 17, 24) !important;
  color: rgb(201, 209, 217) !important;
  font-weight: 900;
}
a {
  color: white;
}
a:hover {
  color: orange;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.title {

  text-align: center;
  font-size: 300%;
  margin-bottom: 7%;
  margin-top: 5%;
}