.bground {
  width: 40%;
}
.landingPage {
  position: absolute;
  width: 100%;
  height: 100%;
}


#homeButton {
  position: relative;
  z-index: 2;
  width: 200px;
  top: 2%;
  left: 2%;
  transform: translate(0, 0%);
  animation: fill 0.5s ease forwards 3s;
}
#homeButtonLand {
  position: relative;
  z-index: 2;
  width: 60%;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fill 0.5s ease forwards 3.5s;
}



