@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
.bground {
  width: 40%;
}
.landingPage {
  position: absolute;
  width: 100%;
  height: 100%;
}


#homeButton {
  position: relative;
  z-index: 2;
  width: 200px;
  top: 2%;
  left: 2%;
  -webkit-transform: translate(0, 0%);
          transform: translate(0, 0%);
  -webkit-animation: fill 0.5s ease forwards 3s;
          animation: fill 0.5s ease forwards 3s;
}
#homeButtonLand {
  position: relative;
  z-index: 2;
  width: 60%;
  top: 15%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: fill 0.5s ease forwards 3.5s;
          animation: fill 0.5s ease forwards 3.5s;
}





#textPicBar {
  width: 100%;
  z-index: -1;
  padding: 0%;
  position: relative;
  display: flex;
  flex-direction: column;
}

#text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#para {
  margin-top: 7%;
  width: 30%;
}
#skidsebbe {
  margin-top: 7%;
  width: 30%;
  height: 50%;
  object-fit: cover;
  margin-left: 10%;
}

@media screen and (max-width: 400px) {

  #text {
    display: flex;
    flex-direction: column-reverse;
    align-self: center;
    width: 80%;
  }

  #skidsebbe {
    margin-top: 3%;
    width: 100%;
    height: 50%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 15%;
  }
  #para {
    margin-top: 3%;
    width: 100%;
    text-align: center;
    margin-bottom: 15%;
  }
}

#gallery {
  margin-top: 5%;
  padding: 2%;
}
#goBack {
  fill: white;
  width: 10%;
  margin-top: 5%;
  margin-left: 3%;
}
@media screen and (max-width: 850px) {
  #gallery img {
    width: 100% !important;
    height: 100%;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(12, 17, 24) !important;
  color: rgb(201, 209, 217) !important;
  font-weight: 900;
}
a {
  color: white;
}
a:hover {
  color: orange;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.title {

  text-align: center;
  font-size: 300%;
  margin-bottom: 7%;
  margin-top: 5%;
}
.mainMenu{width:100%;height:40%;position:absolute;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);top:50%;left:50%;list-style-type:none;display:flex;justify-content:space-around}.menuItem{width:20%;position:relative}.menuItem a{height:100%;width:100%;display:block;color:#fff;font-weight:600;text-align:center;text-decoration:none}.menuItem a:hover{color:orange}#icon{fill:#fff;width:100%;height:100%}#icon:hover{fill:orange}@-webkit-keyframes fill{from{fill:transparent}to{fill:#fff}}@keyframes fill{from{fill:transparent}to{fill:#fff}}
.logo{position:absolute;padding:0;margin:0;-webkit-transform:translate(0%, 0%);transform:translate(0%, 0%);top:10px;left:10px;text-transform:uppercase;color:#fff;font-size:30px;font-family:"Rubik Mono One",sans-serif;width:200px;cursor:pointer}
#footer{background:#333;color:#fff;padding:3em 3em 3em 3em;margin-top:10%;text-align:center}#footer a:hover{color:orange}#contact{display:flex;justify-content:center;margin:4%}#linkStyle{color:#fff}@media screen and (max-width: 400px){#footer{width:100%}#contact{flex-direction:column}}
#projectCanvas img {
  max-width: 40%;
  margin: 5%;
  object-fit: cover;
}
#projectCanvas p {
  width: 20%;
  margin: 5%;
}
#projectCanvas {
  display: flex;
  justify-content: center;
}
#reportLink {
  color: orange;
}
#reportLink:hover {
  color: white;
}
@media screen and (max-width: 1280px) {
  #projectCanvas {
    flex-direction: column;
  }
  #projectCanvas img {
    max-width: 60%;
    margin-left: 20%;
  }
  #projectCanvas p {
    width: 60%;
    margin-left: 20%;
  }
}

#projectTitle{font:"Rubik Mono One",sans-serif;color:#fff;text-align:center;font-size:300%;margin-bottom:7%;margin-top:5%}
