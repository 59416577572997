#gallery {
  margin-top: 5%;
  padding: 2%;
}
#goBack {
  fill: white;
  width: 10%;
  margin-top: 5%;
  margin-left: 3%;
}
@media screen and (max-width: 850px) {
  #gallery img {
    width: 100% !important;
    height: 100%;
  }
}
