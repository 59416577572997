#footer {
    background: #333;
    color: white;
    padding: 3em 3em 3em 3em;
    margin-top: 10%;
    text-align: center;
  }
  #footer a:hover {
    color: orange;
  }
  #contact {
    display: flex;
    justify-content: center;
    margin: 4%;
  }
  #linkStyle {
    color: white;
  }
  @media screen and (max-width: 400px) {
    #footer {
      width: 100%;
    }
    #contact {
      flex-direction: column;
    }
  }
  