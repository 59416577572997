@import "../_variables.scss";

.logo{
    position: absolute;
    padding:0;
    margin:0;
    transform: translate(0%, 0%);
    top:10px;
    left:10px;
    text-transform: uppercase;
    color:$font_color;
    font-size:30px;
    font-family: $font;   
    width:200px;
    cursor: pointer;
}